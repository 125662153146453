@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Metropolis";
    src: url('/public/Metropolis-Regular.otf');
    font-weight: 400;
  }
  @font-face {
    font-family: "Metropolis";
    src: url('/public/Metropolis-Bold.otf');
    font-weight: 700;
  }
}

/* Remove spin buttons of number inputs */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Hide close icon of Google Maps Marker */
.gm-ui-hover-effect {
  display: none !important;
}

body {
  font-family: Metropolis, serif;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 44px;
}

h2 {
  font-family: Metropolis, serif;
  font-size: 1.25rem;
  font-weight: 700;
}

h3 {
  font-family: Metropolis, serif;
  font-size: 1.125rem;
  font-weight: 700;
}

button {
  padding: 10px 12px;
}

p {
  font-size: 14px;
}
